<template>
  <div class="category-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <section>
      <div class="container-fluid">
        <h1 class="header">
          {{ title }}
        </h1>
      </div>

      <div class="cards-container" :class="item_image_shape">
        <div
          v-for="item in items"
          :key="item.id"
          :class="{ 'store-item': followers }"
        >
          <carousel-item
            :item="item"
            :item-type="item_image_shape"
            @click="getData"
          />
        </div>

        <observer @intersect="onIntersect" />
      </div>
    </section>

    <div v-show="is_loading" class="loader-container">
      <loader color="#798B96" />
    </div>

    <div v-if="!is_loading && items.length < 1" class="loader-container">
      <p>No items left</p>
    </div>

    <div class="pagination-container">
      <v-pagination
        v-if="pagination"
        v-model="page"
        :length="paginations_count"
      />
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';
  import { matchString } from '@/utils/matchString';
  import Auth from '@/service/authService';
  import Loader from '@/components/loader/loader';
  import rtm from '@/service/realTimeMessages';
  import { debounce } from 'lodash';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import Observer from 'src/components/Observer.vue';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'CategoryPage',
    components: {
      CarouselItem,
      Loader,
      Observer,
    },
    data() {
      return {
        pagination: false,
        page: 1,
        totalCount: null,
        currentCount: 0,
        count: 20,
        paginations_count: 2,
        is_loading: true,
        add_new_pagination_page: true,
        default_link: '',
        items: [],
        title: 'Items',
        item_image_shape: 'horizontal',
        aspect_ratio: '1x1',
        url: '',
        metadata: {},
      };
    },
    computed: {
      getVideosList() {
        return this.$store.getters['wishlist/getVideosList'];
      },
      followers() {
        return this.$route.query.title === 'Followed stores';
      },
      isOthersSection() {
        return this.$route.query.title === 'Other Products';
      },
      isAllEventsSection() {
        return this.$route.params.id === 'all-events';
      },
    },
    watch: {
      page: async function (newValue) {
        this.is_loading = true;
        const url =
          this.default_link + `?start=${(newValue - 1) * 20}&limit=20`;
        this.url = url;

        await this.getItems(url).finally(() => {
          this.is_loading = false;
        });

        if (
          this.add_new_pagination_page &&
          this.paginations_count === newValue
        ) {
          this.paginations_count++;
        }
      },

      getVideosList: {
        handler(items) {
          const isWatchLater = this.default_link.includes('watch_later');
          const isRecent = this.default_link.includes('recent');

          if (Auth.get('user').guest && (isWatchLater || isRecent)) {
            this.$router.push('/');
          }

          if (isWatchLater) {
            this.items = Object.values(items);
          }
        },
        deep: true,
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.loading = true;

        if (vm.isRealtimeItems(to.query?.link) && !vm.$rtmWasInited) {
          return;
        }

        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.loading = true;

      this.getData(to);
      next();
    },

    mounted() {
      const { title } = this.$route.query;
      if (title) {
        this.metadata.title = title;
      }
      $stats.send('page_opened', {
        page_type: 'category',
      });

      this.$bus.$on('toggle:heart', this.removeWishList);

      if (this.isRealtimeItems(this.$route.query?.link)) {
        this.rtmSubscription = rtm.on(
          ['started', 'on_air_now', 'finished'],
          debounce(() => {
            this.getData(this.$route);
          }, 1000),
        );
      }
    },
    beforeDestroy() {
      rtm.off(this.rtmSubscription);
    },
    methods: {
      calculateWidth() {
        this.windowSize = window.innerWidth;
      },
      isRealtimeItems(link) {
        return (
          link && matchString(link, ['/on_air/', '/coming_soon/', 'all-events'])
        );
      },
      async getItems(url = this.url) {
        await amsClient.callAms(url, { cache: false }).then((data) => {
          this.items = data.items;
          this.add_new_pagination_page = !!data.head.more_items_link;
        });
      },
      removeWishList(id) {
        this.items = this.items.filter((item) => item.id !== id);
      },
      switchCardShape(data) {
        const EVENTS_URL = '/on_air/';
        const STORES_URL = '/shoprzapi/';
        const RECENT_WATCH_LATER_URL = '/stk/get/watch_later';
        const RECENT_PRODUCTS_URL = '/stk/get/';
        const OTHER_PRODUCTS_URL = '/filter/shop_product/';

        if (
          this.default_link.includes(EVENTS_URL) ||
          this.default_link.includes(RECENT_WATCH_LATER_URL) ||
          this.default_link.includes('recent/')
        ) {
          this.aspect_ratio = '16x9';
          this.item_image_shape = 'horizontal';
        } else if (this.default_link.includes(STORES_URL)) {
          this.aspect_ratio = '1x1';
          this.item_image_shape = 'circle';
        } else if (
          matchString(this.default_link, [
            RECENT_PRODUCTS_URL,
            OTHER_PRODUCTS_URL,
          ])
        ) {
          this.aspect_ratio = '1x1';
          this.item_image_shape = 'vertical';
        } else {
          this.aspect_ratio = data.head.aspect_ratio;
          this.item_image_shape = data.head.item_image_shape;
        }
      },
      onIntersect() {
        if (this.currentCount >= this.totalCount) {
          return;
        }

        if (this.is_loading) {
          return;
        }

        const route = {
          ...this.$route,
          query: {
            ...this.$route.query,
            link: this.$route.query.link.replace(
              'start=0',
              `start=${this.currentCount}`,
            ),
          },
        };

        this.getData(route);
      },
      async getData(route) {
        this.setCountForDifferentWindowSize();

        this.is_loading = true;

        this.default_link = route.query?.link;

        if (this.isAllEventsSection) {
          const onAirLink = route.query?.linkOnAir;
          const comingSoonLink = route.query?.linkComingSoon;
          const [onAir, comingSoon] = await Promise.all([
            onAirLink && amsClient.callAms(onAirLink),
            comingSoonLink && amsClient.callAms(comingSoonLink),
          ]);

          this.items = [...onAir.items, ...comingSoon.items];

          const onAirCount = onAir?.head?.count || 0;
          const onAirTotal = onAir?.head?.total || 0;
          const comingSoonCount = comingSoon?.head?.count || 0;
          const comingSoonTotal = comingSoon?.head?.total || 0;

          this.totalCount = onAirTotal + comingSoonTotal;
          this.currentCount = onAirCount + comingSoonCount;
          this.title = 'Live & Upcoming Events';
          this.is_loading = false;
          const timeout = setTimeout(() => {
            document.title = 'Live and Upcoming Events';
            clearTimeout(timeout);
          }, 100);

          return;
        } else if (this.isOthersSection) {
          this.default_link = this.default_link?.replace(
            'limit=20',
            `limit=${this.count}`,
          );
        } else if (this.default_link?.includes('/coming_soon/?')) {
          this.default_link = this.default_link;
        } else {
          this.default_link = `${this.default_link}?limit=${this.count}&start=${this.items.length}`;
        }

        if (this.isRealtimeItems(this.default_link) && this.$eventsTimestamp) {
          this.default_link = this.default_link + '&t=' + this.$eventsTimestamp;
          if (this.default_link?.includes('coming_soon/')) {
            this.default_link = route.query
              ? this.default_link +
                `${
                  route.query?.reflink ? '&reflink=' + route.query.reflink : ''
                }`
              : this.default_link;
          }
        }

        await amsClient
          .callAms(this.default_link)
          .then(async (data) => {
            const metadata = normaliseMetaTags(data.head);

            this.metadata = { ...metadata, ...this.metadata };
            if (
              data.head.itype === 'section_filters_shoprz' &&
              data.head.title != 'Uploads'
            ) {
              this.pagination = true;

              await this.getItems(data.head.more_items_link);
            } else {
              if (this.default_link.includes('coming_soon/')) {
                this.items = data.items.filter((item) => !item.is_live);
              } else {
                this.items = [...this.items, ...data.items];
                this.totalCount = data.head.total || data.head.count || null;
                this.currentCount = this.items.length;

                if (data.items[0]?.itype === 'shop_product') {
                  this.$gtag('event', 'view_item_list', {
                    ecommerce: {
                      item_list_name: data.head.title,
                      items: data.items.map(
                        (item) => ({
                          item_id: item.id,
                          item_name: item.title,
                          item_list_id: data.head.id,
                          item_list_name: data.head.title,
                          store_name: item.creator.name,
                          price: item.price,
                        }),
                      ),
                    },
                  });
                }
              }
            }

            this.title = route.query?.title.includes('^*^')
              ? route.query?.title.replaceAll('^*^', '&')
              : route.query?.title;

            this.switchCardShape(data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.is_loading = false;
          });
      },

      setCountForDifferentWindowSize() {
        if (window.innerWidth <= 1800 && window.innerWidth > 1250) {
          this.count = 60;
        } else if (window.innerWidth <= 1250 && window.innerWidth > 800) {
          this.count = 40;
        } else if (window.innerWidth <= 800) {
          this.count = 15;
        } else {
          this.count = 200;
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .header {
    margin-top: 30px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .category-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    justify-content: center;
    gap: 16px;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &.vertical {
      grid-template-columns: repeat(auto-fit, minmax(159px, max-content));
    }

    &.circle {
      grid-template-columns: repeat(auto-fit, minmax(135px, max-content));
    }

    &.horizontal {
      grid-template-columns: repeat(auto-fit, minmax(260px, max-content));
    }
  }

  .store-item {
    list-style-type: none;
    margin-left: 20px;
    margin-bottom: 50px;
    width: 135px;
  }

  .pagination-container {
    max-width: 500px;
    margin: 0 auto 45px auto;
  }

  .loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>